import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData, putData } from "../../../services/HttpServices";
import { AlertError, AlertSuccess } from "../../../components/Alerts";
import { setPageTitle } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { PrimaryButton } from "../../../components/Button";
import { useReactToPrint } from 'react-to-print';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
import { Invoice } from "./invoice";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
const SaleOrderView = () => {

  const componentRef = useRef();
  const dispatch = useDispatch();

  /*Fetch user id */
  const navigate = useNavigate();
  const { id } = useParams();
  /*Inital state data*/
  const [orderData, setOrderData] = useState();
  const [orderItems, setOrderItems] = useState([]);
  const [orderRecipes, setOrderRecipes] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [validateInput, setValidateInput] = useState(true);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);


  /*Get order data*/
  const getOrderData = () => {
    getData("/orders/details/" + id).then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        if (responseData.success) {
          setOrderData(responseData.data);
          setOrderItems(responseData.data.items)
          setOrderRecipes(responseData.data.recipe)
          setHistoryData(responseData.data.history)
        } else {
          AlertError(responseData.message);
          navigate("/orders");
        }
      }
    });
  };

  const rowUpdatehandler = (index, value, type) => {
    let items = [...orderItems];
    items[index][type] = value;
    const cases = value / items[index]["size"];
    if (cases % 1 === 0) {
      setValidateInput(true)
    } else {
      setValidateInput(false)
    }
    items[index]["cases"] = cases ? cases : 0
    setOrderItems(items)

  }
  const updateRowHandler = () => {
    let nextState = !isUpdate
    if (!nextState) {
      getOrderData();
    }
    setIsUpdate(nextState)
  }
  const updateRowItemsHandler = () => {
    if (window.confirm("Are you sure wan't to update items?")) {
      putData("/orders/updateitems/row", orderData.items).then((response) => {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.success) {
            AlertSuccess(responseData.message)
            setIsUpdate(false)
            getOrderData()
          }
        }
      });
    }
  }


  const handlePrint = async (e) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const printButton = document.getElementById("printButton");
    const content = document.getElementById("whole-page");
    const orderHistory = document.getElementById("orderHistory");
  
    if (orderHistory) {
      orderHistory.style.display = 'none';
    }

    if (printButton) {
      printButton.style.display = 'none';
    }

    html2canvas(content, {
      scale: 2, // Improves rendering quality
      useCORS: true, // Handles external assets
    }).then(async (contentCanvas) => {
    
      const totalHeight = contentCanvas.height;
      const totalWidth = contentCanvas.width;

      let pdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [totalWidth, totalHeight],
        compressPdf: false,
      });

      pdf.addImage(
        contentCanvas.toDataURL("image/png"),
        "PNG",
        15,
        15,
        contentCanvas.width,
        contentCanvas.height
      );

      const blob = pdf.output("blob");
      setPdfBlob(blob);
      setIsPreviewOpen(true); // Open the PDF preview

      if (content) {
        content.style.display = 'none';
      }
  
    });
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setPdfBlob(null); // Clear the blob when closing
    const orderHistory = document.getElementById("orderHistory");
    const printButton = document.getElementById("printButton");

    const content = document.getElementById("whole-page");
    
    // Temporarily hide the orderHistory div

    if (content) {
      content.style.display = 'block';
    }

    if (orderHistory) {
      orderHistory.style.display = 'block';
    }

    if (printButton) {
      printButton.style.display = 'block';
    }
  };



  useEffect(() => {

    getOrderData();
    dispatch(setPageTitle('Order Details'))
    dispatch(setBreadcrumbs([{
      "name": 'Dashboard',
      "link": '/dashboard',
    },
    {
      "name": 'Sale Orders',
      "link": '/orders',
    },
    {
      "name": 'Order Details',
      "link": '/orders/details/' + id,
    }
    ]))
    // eslint-disable-next-line
  }, [id, navigate, dispatch]);


  return (
    <DefaultLayout>
           {isPreviewOpen && (
        <div className="pdf-preview">
          <PrimaryButton clickfun={closePreview} buttonClass="danger float-end no-print" buttonText="Close Preview" iclassName="file-earmark-pdf-fill" />
          <iframe
            title="PDF Preview"
            src={URL.createObjectURL(pdfBlob)}
            width="100%"
            height="600px"
            style={{ border: 'none' }}
          />
        </div>
      )}
      <section className="section invoice-section" id="whole-page" style={{ width: '100%',minWidth:'100%'}} >
        <div className="row">
          <div className="card">
            <div className="card-body mt-3">
              <div className="col-12">
                <div className="order-detail-col mb-0">
                  {orderData &&
                    <div className="grid invoice mt-3 mb-3" >
                      <div className="grid-body">
                        <div className="invoice-title">
                          <div className="row">
                            <div className="col-md-4 col-4">
                              <h4>Order:#{orderData.sage_code}</h4>
                            </div>
                            <div className="col-md-4 col-4" style={{textAlign:"center"}}>
                              {!isPreviewOpen &&
                                  <img
                                    src="/images/pdf-logo.png"
                                    alt="SimplyFruit"
                                    style={{height: 'auto', textAlign: "center",width:'45%' }}
                                  />
                                }
                              </div>

                            <div className="col-md-4 col-4">
                              <PrimaryButton clickfun={handlePrint} buttonClass="primary view-btn float-end no-print" buttonText="Print" iclass="file-earmark-pdf-fill" id="printButton" />
                              <div className={'hide'}>
                                <Invoice ref={componentRef} orderData={orderData} orderItems={orderItems} orderRecipes={orderRecipes} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-md-4 col-12 mb-2">

                            <div className="billed-col">
                              <h6 className="fw-bold">Client:</h6>
                              <address>
                                <b> {orderData.client_name} </b><br />
                                {orderData.client_address1},{orderData.client_address2}<br />
                                {orderData.client_county},{orderData.client_country}<br />
                                {orderData.client_postcode && <br />}
                                <i className="bi bi-person"></i> {orderData.client_contact_name ? orderData.client_contact_name : 'N/A'}<br />
                                <i className="bi bi-phone-vibrate"></i> {orderData.client_contact_number ? orderData.client_contact_number : 'N/A'}
                              </address>
                            </div>
                          </div>
                          <div className="col-md-4 col-12 mb-2">

                            <div className="billed-col">
                              <h6 className="fw-bold">Ship To:</h6>
                              <address>

                                <b> {orderData.contact_name} </b><br />
                                {orderData.address_1},{orderData.address_2}<br />
                                {orderData.county} ,  {orderData.country}<br />
                                {orderData.postcode}<br />
                                <i className="bi bi-phone-vibrate"></i> {orderData.contact_number ? orderData.contact_number : 'N/A'}<br />
                                GLN Code:  {orderData.gln_code}<br />
                                {orderData.vehicle_number && <span>Vehicle Number:  {orderData.vehicle_number}</span>}
                              </address>
                            </div>  </div>
                          <div className="col-md-4 col-12 mb-2">

                            <div className="billed-col">
                              <h6 className="fw-bold">Order Information:</h6>
                              <address>

                                <span>Delivery Date: </span>{orderData.delivery_date}<br />
                                <span>Tax Code: </span>{orderData.tax_code}<br />
                                <span>Collection Location: </span>{orderData.loading_place ? orderData.loading_place : 'N/A'}<br />
                                <span>Status: </span> <span className={`fw-bold badge bg-${orderData.status_bgcolor}`}>{orderData.order_status}</span><br />
                              </address>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12 table-responsive">
                            <h5>ORDER SUMMARY</h5>
                            <table className="table table-striped">
                              <thead className="order-smr">
                                <tr>
                                  <th scope="col"><strong>#</strong></th>
                                  <th scope="col"><strong>Code</strong></th>
                                  <th scope="col"><strong>Partner Code</strong></th>
                                  <th scope="col"><strong>Description</strong></th>
                                  <th scope="col"><strong>Barcode</strong></th>
                                  <th scope="col"><strong>Quantity</strong></th>
                                  <th scope="col"><strong>Pallet</strong></th>
                                  <th scope="col"><strong>Line</strong></th>
                                  {!isUpdate && <th scope="col"><strong>Status</strong></th>}
                                </tr>
                              </thead>
                              <tbody className="order-sum">
                                {orderItems.length > 0 && orderItems.map((item, index) => (
                                  <React.Fragment key={index} >
                                    {!isUpdate && <tr className={item.item_status_id === 8 ? 'bg-danger text-white' : ''}>
                                      <th scope="row">{index + 1}</th>
                                      <td>{item.product_code}</td>
                                      <td>{item.partner_code}</td>
                                      <td>{item.product_description}
                                        <small className={`mx-2 fw-bold text-wrap badge bg-secondary`}>  QA Required : {item.is_qa_required === 1 ? 'Yes' : 'No'} </small>
                                      </td>
                                      <td>{item.barcode}</td>
                                      <td>{item.quantity}</td>
                                      <td dangerouslySetInnerHTML={{ __html: item.pallet }}></td>
                                      <td>{item.line}</td>
                                      <td>{item.itemstatus}</td>
                                    </tr>}

                                    {isUpdate && <tr>
                                      <th scope="row">{index + 1}</th>
                                      <td>{item.product_code}</td>
                                      <td>{item.partner_code}</td>
                                      <td>{item.product_description}
                                        <small className={`mx-2 fw-bold text-wrap badge bg-secondary`}>  QA Required : {item.is_qa_required === 1 ? 'Yes' : 'No'} </small>
                                      </td>
                                      <td>{item.barcode}</td>
                                      <td style={{ width: "10%" }}><input type="number" min={1} className="form-control" defaultValue={item.quantity} onInput={(e) => rowUpdatehandler(index, e.target.value, 'quantity')} />
                                        <small className="text-info">Size: {parseInt(item.size)}</small>
                                      </td>

                                      <td dangerouslySetInnerHTML={{ __html: item.pallet }}></td>
                                      <td>{item.line}</td>
                                    </tr>
                                    }
                                  </React.Fragment>
                                ))}
                                {orderData.status_id === 1 && orderData.production_sync === 0 &&
                                  <tr>
                                    <td className="text-center" colSpan="9">
                                      {isUpdate &&
                                        <React.Fragment>
                                          <PrimaryButton buttonText='Return' buttonClass='warning float-end' title='Cancel' clickfun={() => updateRowHandler()} iclass='arrow-counterclockwise' />
                                          <PrimaryButton disabled={!validateInput} buttonText='Update Items' buttonClass='danger float-end' title='Update Items' clickfun={() => updateRowItemsHandler()} iclass='database' />
                                          {!validateInput && <small className="text-danger">Invalid quantity entered as per box requirement. Please try again</small>}
                                        </React.Fragment>
                                      }
                                      {!isUpdate &&
                                        <PrimaryButton buttonText='Edit Items' buttonClass='success float-end' title='Edit Items' clickfun={() => updateRowHandler()} iclass='pencil-fill' />}
                                    </td>
                                  </tr>

                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row mt-5">
                          <div className="col-md-12 table-responsive">
                            <h5>Raw Material Information</h5>
                            <table className="table table-striped">
                              <thead className="order-smr">
                                <tr className="line">
                                  <th scope="col"><strong>#</strong></th>
                                  <th scope="col"><strong>Recipe</strong></th>
                                  <th scope="col"><strong>Raw Material (KG)</strong></th>
                                </tr>
                              </thead>
                              <tbody className="order-sum">
                                {
                                  orderRecipes.length > 0 && orderRecipes.map((raw, rindex) => (
                                    <tr key={rindex} className={`fw-bold ${rindex % 2 !== 0 ? "bg-warning" : "bg-info"}`}>
                                      <td>{rindex + 1}</td>
                                      <td>{raw.title}</td>
                                      <td>{Number(raw.raw).toFixed(2)}</td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>


                  }
                </div>
              </div>
            </div>




            {/* order history */}
            {historyData && historyData.length > 0 &&
              <div className="row" id="orderHistory">

                <div className="panel-heading mb-3">
                  <h5 className="p-3"><b>Order history</b></h5>
                </div>

                <div className="card-body mt-3">
                  <ul className="notification">
                    {historyData.map((history, hIndex) => (
                      <li key={hIndex}>
                        <div className="notification-time"><span className="date fw-bold"> {history.date}</span><span className="time ms-2 "> {history.time}</span></div>
                        <div className="notification-icon"><span></span></div>
                        <div className="notification-time-date mb-2 d-block d-md-none"><span className="date fw-bold"> {history.date}</span><span className="time ms-2 "> {history.time}</span></div>
                        <div className="notification-body">
                          <div className="media mt-0">
                            <div className="media-body ms-3 d-flex">
                              <div className="">
                                <p className="tx-14 text-dark mb-0 tx-semibold fw-bold" dangerouslySetInnerHTML={{ __html: history.heading }} ></p>
                                <p className="tx-14 text-dark mb-0 tx-semibold" dangerouslySetInnerHTML={{ __html: history.description }} ></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

              </div>
            }

          </div>

        </div>

      </section>
    </DefaultLayout>
  );
};
export default SaleOrderView;