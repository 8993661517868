import React, { useState, useEffect } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom"
import { getData,postData } from "../../../services/HttpServices";
import { PrimaryButton, DeleteButton } from "../../../components/Button";
import { AlertError,AlertSuccess } from "../../../components/Alerts";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { setPageTitle  } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import Addaddresspopup from "../clients/addresspopup";
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
const CreateSaleOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()


  const [clientId, setClientId] = useState(0);
  const [selectedClient, setSelectedClient] = useState(null);
  const [addressId, setAddressId] = useState(0);
  const [deliveryDate, setDeliveryDate] = useState("");
  const [functions, setFunctions] = useState("");
  const [packing, setPacking] = useState("");
  const [deliveryinstructions, setDeliveryInstructions] = useState("");
  const [show, setShow] = useState(false);
  const [todayProductionCheck, setTodayProductionCheck] = useState(true);
  const [todayProduction, setTodayProduction] = useState(0);

  const [clientData, setClientData] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [productRows, setProductRows] = useState([{ product_id: 0,partner_code:'', quantity: 0,size:0, box:0, cases:0 }]);
  const [productData, setProductData] = useState([]);
  const [enabled, setEnabled] = useState(true);
 

  const onSubmit = () => {
    if (!clientId) {
      AlertError("Please choose a client");
      return;
    }
    if (!addressId) {
      AlertError("Please choose a address");
      return;
    }
    if (!deliveryDate) {
      AlertError("Please select a delivery date");
      return;
    }
    if (productRows.length === 0) {
      AlertError("Please add a product.");
      return;
    }
    let errors = productRows.filter((res) => {
      return res.product_id === 0 || !res.partner_code || res.quantity === 0 || (res.cases % 1 !== 0)
    });
    if (errors.length > 0) {
      AlertError("The product section required attention.");
      return;
    }
    let newDeliveryDate = deliveryDate.toLocaleDateString("en-UK");
   
    const postArr = {
        deliverydate: newDeliveryDate,
        client: clientId,
        addressId: addressId,
        function: functions,
        deliveryinstructions: deliveryinstructions,
        todayproduction: todayProduction,
        productRows: productRows
    }
    setEnabled(false)
    postData('/order',postArr).then((response) => {
      setEnabled(true)
        let resposnseData = response.data
        if(resposnseData.success === false){
            AlertError(resposnseData.message);
            return
        }
        AlertSuccess(resposnseData.message)
        navigate("/orders");
    }).catch(e=>{
      setEnabled(true)
        AlertError(e);
    })
  };

  const addProduct = () => {
    let newAdded = [...productRows, { product_id: 0, partner_code: '', quantity: 0,size:0, box:0, cases: 0}];
    setProductRows(newAdded);
   
  };
  const removeProduct = (index) => {
    let newRows = productRows.filter((arrow, key) => key !== index);
    setProductRows(newRows);
  };

  const updateProductRow = (res, index, type) => {
    if(type === 'product'){
      let updateProduct = [...productRows];
      updateProduct[index]["product_id"] = (res) ? res.value : 0
      updateProduct[index]["size"] = (res) ? res.size : 0
      setProductRows(updateProduct);
    }
    if(type === 'partner_code'){
      let updateProduct = [...productRows];
      updateProduct[index]["partner_code"] = res.target.value
      setProductRows(updateProduct);
    }
    if(type === 'quantity'){
      let updateQty = [...productRows];
      const cases = res.target.value / updateQty[index]["size"];
      updateQty[index]["cases"] = cases ? cases : 0
      // if(cases % 1 === 0){
        updateQty[index]["quantity"] = res.target.value;
       //}
       setProductRows(updateQty);
    }
       
  }
  const clientChangeHandler = (res) => {
    if(res){
        setSelectedClient(res)
        setClientId(res.value)
        getAddressData(res.value)
    }else{
      setClientId(0);
      setSelectedClient(null)
      setAddressData([])
      
    }
  }


  /*Get Products Data*/
  const getProductData = () => {
    getData("/products/filter").then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        setProductData(responseData.data);
      }
    });
  };

  /*Get Products Data*/
  const getAddressData = (id) => {
    getData("/clients/addresses/filter/"+id).then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        setAddressData(responseData.data);
      }
    });
  };
  
                        
  useEffect(() => {
    const getClients = () => {
      getData("/clients/filter").then((response) => {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.success) {
            setClientData(responseData.data);
            //console.log(responseData.data)
            //setSelectedClient()
          }
        }
      });
    };
    getClients();
    getProductData();
      dispatch(setPageTitle('Create Sale Order'))
      dispatch(setBreadcrumbs([
        {
        "name":'Dashboard',
        "link":'/dashboard',
        },
        {
            "name":'Sale Orders',
            "link":'/orders',
        }  ,
        {
            "name":'Create Sale Order',
            "link":'/orders/add',
        }
]))
  }, [dispatch]);

  const showAddressPopUp = () =>{
    if(!clientId){
      AlertError('Please select a client first.')
      return;
    }
    setShow(true)
  }
  const hideAddressPopUp = () =>{
      refreshAddress()
      setShow(false)
  }
  const refreshAddress = () => {
    getAddressData(clientId)
  }
  return (
    <DefaultLayout >
      <section className="section">
        <div className="row">
          <div className="card">
            <div className="card-body mt-5">
                    <div className="row mb-3">
                          <label htmlFor="client" className="col-3 col-form-label">Client<span className="text-danger">*</span></label>
                            <div className="col-4">
                                    <Select
                                        options={clientData}
                                        defaultValue={clientId}
                                        onChange={clientChangeHandler}
                                        isSearchable={true}
                                        id="client"
                                        isClearable={true}
                                        placeholder='Select a client'
                                      />
                                    </div>
                                   
                         </div>
                         <div className="row mb-3">
                          <label htmlFor="delivery_address" className="col-3 col-form-label">Delivery Address<span className="text-danger">*</span></label>
                            <div className="col-4">
                                    <Select
                                        options={addressData}
                                        defaultValue={addressId}
                                        value={addressData?addressData[0]:0}
                                        onChange={(res)=> res ? setAddressId(res.value) : setAddressId(0)}
                                        isSearchable={true}
                                        id="delivery_address"
                                        isClearable={true}
                                        placeholder='Select a address'
                                      />
                                      <button onClick={showAddressPopUp} className="btn btn-primary btn-sm mt-2" title="Add New Address"><i className="bi bi-database-fill-add" /></button>
                                    </div>
                                   
                         </div>
                         <div className="row mb-3">
                          <label htmlFor="deliverydate" className="col-3 col-form-label">Delivery Date<span className="text-danger">*</span></label>
                            <div className="col-4">
                            <DatePicker
                                    className="form-control col-6"
                                    selected={deliveryDate}
                                    id="deliverydate"
                                    onChange={(date) => {
                                      setDeliveryDate(date)

                                      let selectedDate = date.toLocaleDateString("en-UK");
                                      var todaysDate = new Date();
                                       todaysDate = todaysDate.toLocaleDateString("en-UK");
                                      if(selectedDate === todaysDate) {
                                        setTodayProductionCheck(false)
                                      }else{
                                        setTodayProductionCheck(true)
                                      }
                                    }}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Click to select a date"
                                    monthsShown={2}
                                    minDate={new Date()}
                                    isClearable={deliveryDate}
                                  />
                              </div>
                                   
                         </div>
                        {!todayProductionCheck && <div className="row mb-3" >
                                    <label htmlFor="is_today_production" className="col-sm-3 col-form-label">Will Go For Today Production ?</label>
                                    <div className="col-sm-4">
                                        <input
                                            type="checkbox"
                                            id="is_today_production"
                                            onChange={(res) => res.target.checked ? setTodayProduction(1) : setTodayProduction(0)}
                                            checked={todayProduction}
                                            defaultValue={todayProduction}
                                            disabled={todayProductionCheck} 
                                            />
                                        
                                    </div>
                                   
                                </div>  }
                         <div className="row mb-3">
                          <label htmlFor="functions" className="col-3 col-form-label">Function</label>
                            <div className="col-4">
                            <input
                                    className="form-control col-6"
                                    defaultValue={functions}
                                    id="functions"
                                    onInput={(res) => setFunctions(res.target.value)}
                                  />
                              </div>
                                   
                         </div>
                         <div className="row mb-3">
                          <label htmlFor="packing" className="col-3 col-form-label">Packing</label>
                            <div className="col-4">
                            <input
                                    className="form-control col-6"
                                    defaultValue={packing}
                                    id="packing"
                                    onInput={(res) => setPacking(res.target.value)}
                                  />
                              </div>
                                   
                         </div>
                         <div className="row mb-3">
                          <label htmlFor="deliveryinstructions" className="col-3 col-form-label">Delivery Instructions</label>
                            <div className="col-4">
                            <input
                                    className="form-control col-6"
                                    defaultValue={deliveryinstructions}
                                    id="deliveryinstructions"
                                    onInput={(res) => setDeliveryInstructions(res.target.value)}
                                  />
                              </div>
                                   
                         </div>
              <hr />
              <div className="col-12 mb-3">
                <h5 className="card-title">Products<span className="text-danger">*</span></h5>
              </div>
              {productRows.length > 0 &&
                productRows.map((product, index) => (
                  <div className="row g-3 mb-3" key={index}>
                    <div className="col-4">
                      <label className="col-form-label">Product<span className="text-danger">*</span></label>
                      <Select
                        options={productData}
                        defaultValue={product.product_id}
                        className="col-12"
                        isSearchable={true}
                        isClearable={true}
                        onChange={(res)=>updateProductRow(res, index,'product')}
                      />
                      {product.size > 0 && <span className="text-info">(Size: {product.size})</span> }
                    </div>
                    <div className="col-4">
                      <label className="col-form-label">Partner Code<span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={product.partner_code}
                        onInput={(res)=>updateProductRow(res, index,'partner_code')}
                      />
                    </div>
                    <div className="col-4">
                      <label className="col-form-label">Quantity ( KG ) <span className="text-danger">*</span></label>
                      <input
                        type="number"
                        className="form-control"
                        min={1}
                        defaultValue={product.quantity}
                        onInput={(res)=>updateProductRow(res, index,'quantity')}
                      />
                      { productRows[index]["cases"] % 1 !== 0 && <small className="text-danger"> Invalid quantity entered as per box requirement. Box size should be interger value : {productRows[index]["cases"]}  </small> }
                      { productRows[index]["cases"] % 1 === 0 && <small className="text-info"> Cases : {productRows[index]["cases"]}  </small> }
                     
                    </div>
                    { productRows.length > 1 && 
                    <div className="col-4">
                      <div className="mt-2-5">
                        <DeleteButton
                          iclass="trash-fill"
                          buttonClass="danger"
                          clickfun={() => {if(window.confirm('Delete the item?')){ removeProduct(index) };}} 
                        />
                      </div>
                    </div>
                    }
                  </div>
                ))}
              <div className="row g-3 mb-3">
                <div className="col-3">
                  <PrimaryButton
                    title="Add Product"
                    clickfun={addProduct}
                    iclass="plus"
                    buttonClass="primary"
                  />
                </div>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  onClick={onSubmit}
                  className="btn btn-primary"
                  disabled={!enabled}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Addaddresspopup show={show} hideFn={hideAddressPopUp} showAddClient={false} clientData={clientData} selectedClient={selectedClient}    />
    </DefaultLayout>
  );
};
export default CreateSaleOrder;
