import React, { useEffect, useState} from "react"
import { EditButton, DeleteButton, PrimaryButton, AddButton } from "./Button";
import Pagination from "../components/Pagination";
import Skeleton from "react-loading-skeleton";
import SelectOptions from "./SelectOptions";
import DateRange from "./DateRange";
const Table = (props) => {
   
    const [isLoading, setIsLoading] = useState(false)
    const [tableColumns, setTableColumn] = useState([])

    const [totalCounts, setTotalCounts] = useState(0);
    const [sessionsPerPage, setSessionsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);


    const ColorIndicator = (value) => {
        let color;
        let quantity = value?value:0;
      
        if (quantity < 50) {
          color = "red";
        } else if (quantity >= 50 && quantity < 100) {
          color = "yellow";
        } else {
          color = "green";
        }

        return {
            backgroundColor: color,
            padding: "10px",
            color: "black",
          };
      };


   

    useEffect(() => {
        setTableColumn(props.data.column)
        setTotalCounts(props.data.counts)
        setSessionsPerPage(props.data.limit)
        setCurrentPage(props.data.pageno)
        setTimeout(()=>{
            setIsLoading(true)
        },1000)
    }, [props])

    const updateList = (number) => {
        setCurrentPage(number)
        props.updateFn(number)
    }

    
    return ( <>
        <div className="row">
                <div className="col-12 btn-add-div">
                 
                {/* filters */}
                <div className="group_filter">
                    { props.filters && props.filters.length > 0 && props.filters.map((filter, fIndex) => (
                        <div key={fIndex} className={`filter_cls_${fIndex}`}>
                            {
                                filter.type === 'search' && 
                                <div className="datatable-top float-start">
                                    <div className="datatable-search">
                                        <input className={filter.class} ref={filter.ref}  placeholder={'Search'} type={'text'} title={filter.label} onKeyUp={(e) => filter.searchFilterFn(e.target.value)}/>
                                    </div>
                                </div>
                            } 
                            {
                                filter.type === 'daterange' &&
                                    <div className="datatable-top float-start mx-2">
                                        <div className="datatable-search" style={{
                                            'width':'100%'
                                        }}>
                                            <DateRange default={filter.default} startDate={filter.startDate}   endDate={filter.endDate} onDateRangeChange={(res) => filter.dateRangeFn(res)} placeholderText={filter.placeholderText}  />
                                        </div>
                                    </div>
                            } 
                            {
                                filter.type === 'select' &&
                                    <div className="datatable-top float-start mx-2">
                                        <div className="datatable-search react-select-width">
                                            <SelectOptions options={filter.options} default={filter.default} changeFn={filter.changeOptionsFn}  isClearable={filter.isClearable} placeholder={filter.label}  />
                                        </div>
                                    </div>
                            } 
                        </div>
                    ))
                    } 
                    {
                            props.submitFilter &&
                                <React.Fragment>
                                    <PrimaryButton buttonText="Filter" clickfun={props.submitFilter} buttonClass="primary view-btn float-start" iclass='search' />
                                    {props.clearfilter &&
                                     <PrimaryButton buttonText="Clear" clickfun={()=> props.clearfilter()} buttonClass="primary view-btn float-start" iclass='clear' />
                                    }
                                </React.Fragment>
                            } 
                </div>   
                   <div className="group_submenu">
                        { props.submenu && props.submenu.length > 0 && props.submenu.map((menu, mIndex) => (
                            <div key={mIndex} className="submenu_cls">
                                {
                                    menu.fun && 
                                    <PrimaryButton buttonText={menu.label} clickfun={menu.fun}  buttonClass={menu.buttonClass}iclass={menu.iclass} />
                                }

                                {
                                    menu.link && 
                                    <AddButton buttonText={menu.label} link={menu.link}  buttonClass={menu.buttonClass} />
                                }    
                            </div>
                        )) }
                        
                        
                    </div>   
            </div> 
        </div> 
        <div className = "datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns" >
        <div className="datatable-container table-responsive">          
                <table className="table table-hover datatable datatable-table">
                    <thead>
                        <tr className="bg-simply-primary text-white fw-bold">
                            {Object.keys(props.data.column).map((column, index) => (
                                            <th scope="col" key={index}>{tableColumns[column]}</th>
                            ))}
                                        { (props.data.editlink || props.delFun || props.data.viewLink || props.printPdf || props.popupButton || props.data.customBtn)  && <th colSpan={2} scope="col">Actions</th> }
                                        </tr>
                                    </thead>
                                    <tbody>
                                      { isLoading &&   
                                      props.data.list.length > 0 ? props.data.list.map((data, index) => (
                                       
                                            <tr data-index={index} key={index}>
                                                {Object.keys(tableColumns).map((column, keyIndex) => ( 
                                                    <td key={keyIndex}   style={
                                                    column === 'total_quantity'  
                                                      ? ColorIndicator(data['total_quantity']) 
                                                      : undefined
                                                  }> 
                                                   
                                                   {  column === 'status' &&  data[column] === 1 && <span className="badge rounded-pill text-bg-success">Active</span> }
                                                   {  column === 'status' &&  data[column] === 2 && <span className="badge rounded-pill text-bg-danger">In-Active</span> }

                                                   {  column === 'de_status' &&  data[column] === 1 && <span className="badge rounded-pill text-bg-warning">Pending</span> }
                                                   {  column === 'de_status' &&  data[column] === 2 && <span className="badge rounded-pill text-bg-success">Approve</span> }
                                                   {  column === 'de_status' &&  data[column] === 3 && <span className="badge rounded-pill text-bg-success">Rejected</span> }


                                                   {  column === 'production_sync' &&  data[column] === 1 && <span className="badge rounded-pill text-bg-success">Yes</span> }
                                                   {  column === 'production_sync' &&  data[column] === 0 && <span className="badge rounded-pill text-bg-danger">No</span> }

                                                   {  column === 'order_status' && <span className={'badge rounded-pill text-bg-' + data['status_bgcolor']}>{data[column]}</span> }
                                                   { column !== 'order_status' &&  column !== 'status' &&  column !== 'de_status' &&  column !== 'production_sync' && data[column] }
                                                   </td>
                                                ))}
                                        
                                            { (props.data.editlink || props.delFun || props.data.viewLink || props.printPdf || props.popupButton || props.data.customBtn)  && 
                                            <td colSpan={2}>
                                            { props.data.viewLink && 
                                                    <EditButton  iclass={props.data.viewLinkIcon ? props.data.viewLinkIcon : "eye-fill"} link={`${props.data.viewLink}${data.id}`} buttonClass='' title={props.data.viewLinkTitle ? props.data.viewLinkTitle : "History"}/>
                                            } 
                                            { (props.data.editlink || props.delFun) && 
                                             <>
                                                    <EditButton  iclass='pencil-square' link={`${props.data.editlink}${data.id}`} buttonClass=''  title={props.data.editLinkTitle ? props.data.editLinkTitle : 'Edit Product'  } />
                                                    {props.delFun ? <DeleteButton iclass='archive-fill' buttonClass='danger' title='Delete' id={data['id']} clickfun={props.delFun} /> :''
                                                    }
                                              </>
                                            }
                                            { props.downloadPdf &&
                                                <PrimaryButton buttonClass='' iclass='filetype-pdf' clickfun={() => props.downloadPdf(data['id']) } title='Downlaod PDF' /> 
                                            }
                                            { props.popupButton &&
                                                <PrimaryButton buttonClass='primary' iclass='plus' clickfun={() => props.popupButton(data['id']) } title={props.title} /> 
                                            }
                                            { props.data.customBtn && !data.production_sync &&
                                                <PrimaryButton buttonClass='primary'  clickfun={() => props.sendprodction(data['id']) } iclass='send-check' title={props.data.customBtn.title} /> 
                                            }
                                            
                                            </td> }
                                            
                                        </tr>
                                        ))  
                                        :  <tr key={1}>
                                            <td colSpan={ Object.keys(tableColumns).length + 1} >
                                                { !isLoading ?  
                                                        <Skeleton style={{
                                                            width: '100%',
                                                            marginBottom:5,
                                                            marginTop:5,
                                                            height:20
                                                        }} count={10} inline={true} />
                                                        :  <div className='alert alert-warning text-center'>No Records Found</div>
                                                }
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div> 
                            <div className = "datatable-bottom" > {
            totalCounts > 10 && isLoading ?
            <Pagination
                                    itemsCount={totalCounts}
                                    itemsPerPage={sessionsPerPage}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    alwaysShown={true}
                                    updateList={updateList}
                                /> : ''
        } 
        </div> </div > 
        </>
    )
}
export default Table